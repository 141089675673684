
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { formatInputMoney, formatPrice } from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TopupFeeConfigurationController } from "@/app/ui/controllers/TopupFeeConfigurationController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { TopupFeeConfigRequest } from "@/data/payload/api/TopupFeeConfigRequest";
import { LastBalanceData } from "@/domain/entities/LastBalance";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { flags } from "@/feature-flags";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
  components: {
    DetailLayout,
  },
})
export default class CreateTopupFeeConfiguration extends Vue {
  mounted() {
    this.form.ctufAccountType = "Retail";
    this.form.ctufTopUpFee = ""
    this.onGetListPartner("");
  }

  form = new TopupFeeConfigRequest();
  isLoadingPartner = false;
  searchPartner: Array<any> = [];
  listPos: any = [];
  isActive = false;
  isErrorTopupFee = false;

  onChangeTopupFee(value: any) {
    value = +value.replaceAll(".", "");
    this.isErrorTopupFee = value < flags.flag_minimum_top_up_fee.getValue();
  }

  get minimumValue() {
    return formatPrice(flags.flag_minimum_top_up_fee.getValue());
  }

  onChangeFilterPos() {
    const includesAll =
      this.searchPartner.filter((key: any) => key.id === "all").length > 0;
    this.form.ctufIsAllPos = includesAll;
    this.form.ctufPartnerIds = includesAll
      ? []
      : this.searchPartner.map((key: any) => key.id);
  }

  goBack() {
    this.$router.back();
  }

  get isFormValid() {
    return (
      !this.isErrorTopupFee &&
      this.form.ctufAccountType &&
      this.form.ctufTopUpFee !== 0 &&
      this.searchPartner.length &&
       this.form.ctufTopUpFee !== ""
    );
  }

  async onGetListPartner(search: string) {
    try {
      this.isLoadingPartner = true;
      this.listPos = await TopupFeeConfigurationController.getListPartner(
        new RequestListPartner({
          version: "v2",
          search,
          type: "pos",
          limit: 10,
          countryCode: "ID",
          status: "not-banned"
        })
      );
      this.listPos = [
        {
          id: "all",
          name: "Semua POS",
        },
        ...this.listPos.data
          .map((key: LastBalanceData) => {
            return {
              id: key.idClientPartner,
              name: key.nameClientPartner
            };
          })
      ];
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan List Partner!", () =>
          this.onGetListPartner(search)
        )
      );
    } finally {
      this.isLoadingPartner = false;
    }
  }

  filterPos = debounce((search: string) => {
    if (search.length > 2 || !search) this.onGetListPartner(search);
  }, 250);

  async onSubmit() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const payload = new TopupFeeConfigRequest({
      ctufAccountType: this.form.ctufAccountType,
      ctufIsAllPos: this.form.ctufIsAllPos,
      ctufPartnerIds: this.form.ctufPartnerIds,
      ctufStatus: this.isActive ? "active" : "inactive",
      ctufTopUpFee: +this.form.ctufTopUpFee.toString().replaceAll(".", ""),
    });
    try {
      await TopupFeeConfigurationController.saveConfig(payload);
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Konfigurasi Biaya Top Up Berhasil Dibuat!",
          message: "Konfigurasi biaya top up telah dibuat",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success",
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Konfigurasi Biaya Top Up Gagal Dibuat!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  formatRupiah(value: string) {
    if (!value) return value
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  // route logic
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;

  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }
}
